.background {
    background-color: #f5f5f5;
}
.dark .background {
    background-color: #1f1d2b;
}

.item-icon-bg {
    fill: #efeff0;
}
.dark .item-icon-bg {
    fill: #5c5e69;
}

.item-defult-icon-bg {
    background-color: #f4f4f5;
}
.dark .item-defult-icon-bg {
    background-color:  #3c3f4c;
}
/* 

.carbonic-card{
    background-color: #FFFFFF;
} 
.dark .carbonic-card{
    background-color: #2d303e;
} 
.carbonic-card-1{
    background-color: #FBFBFB;
} 
.dark .carbonic-card-1{
    background-color: #353848;
} 
.carbonic-card-2{
    background-color: #FFFFFF;
} 
.dark .carbonic-card-2{
    background-color: #393c4a;
} 
.carbonic-card-3{
    background-color: #FBFBFB;
} 
.dark .carbonic-card-3{
    background-color: #292a39;
} 
.carbonic-card-4{
    background-color: #F0F0F0;
} 
.dark .carbonic-card-4{
    background-color: #393c4a;
} 
.carbonic-card-5{
    background-color: #f6f6f6;
} 
.dark .carbonic-card-5{
    background-color: #3b3e4c;
} 
.carbonic-card-6{
    background-color: #eaeaeb;
} 
.dark .carbonic-card-6{
    background-color: #eaeaeb;
} 
.carbonic-card-7{
    background-color: #fbfbfb;
} 
.dark .carbonic-card-7{
    background-color: #3c3f4c;
} 
.carbonic-card-8{
    background-color: #f5f5f5;
} 
.dark .carbonic-card-8{
    background-color: #22212e;
} 
  */
.search-bg {
    background-color: #ffffff;
}
.dark .search-bg {
    background-color: #3c3f4c;
}
.account-check-bg {
    background-color: #ffffff;
}
.dark .account-check-bg {
    background-color: #3c3f4c;
}

/* .carbonic {
    color: #1f1d2b;
}
.dark .carbonic {
    color: #ffffff;
}

.carbonic-1 {
    color: #c3c3c6;
}
.dark .carbonic-1 {
    color: #6c6e78;
}

.carbonic-2 {
    color: #c4c4c7;
}
.dark .carbonic-2 {
    color: #9e9fa6;
}

.carbonic-3 { 
    color: #c4c4c7;
}
.dark .carbonic-3 {
    color: #767982;
}

.carbonic-4 {
    color: #bebec1;
}
.dark .carbonic-4 {
    color: #767982;
} */

.check-bg {
    background-color: #f3f3f1;
}
.dark .check-bg {
    background-color: #4c4e5a;
}
.compo-bg {
    background:rgba(196, 196, 199,0.85);
}
.dark .compo-bg {
    background:rgba(31, 29, 43,0.85);
}



.bg-logout {
    background-color: #f4e2e3;
}

.dark .bg-logout {
    background-color: #36232f;
}

.menu-label {
    background-color: #fcfcfc;
}
.dark .menu-label {
    background-color: #2d2c39;
}
.red-bg {
    background-color: #dd3643;
}
.green-bg {
    background-color: #56e181;
}
.check-bg {
    background-color: #e9e9eb;
}
.dark .check-bg {
    background-color: #494c5a;
}

.orange-text {
    color: #f48114;
}
.orange-text-2 {
    color: #ffa44e;
}
.orange-text-3 {
    color: #f9c089;
}
.dark .orange-text-3 {
    color: #905929;
}

.orange-bg {
    background-color: #f48114;
}
.orange-bg-2 {
    background-color: #ffa44e;
}
.orange-bg-3 {
    background-color: #f9c089;
}
.dark .orange-bg-3 {
    background-color: #905929;
}

.cyan-text {
    color: #63cfc9;
}
.green-text {
    color: #56e181;
}
.red-text {
    color: #dd3643;
}
.white-text {
    color: #fff;
}

.red-text-1 {
    color: #ec4245;
}

.dark .red-text-1 {
    color: #ffffff;
}

/* .green-text {
    color: #1bd69a;
} */
.menu-phone {
    color: #6b6d78;
}
/* MenuPhone */
.dark .menu-phone {
    color: #c4c4c7;
}

/* .shadow-1 {
    box-shadow: 0px 0px 10px rgba(60, 63, 76, 0.15);
} */
.shadow-1 {
    box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);
}
.shadow-svg {
    filter: drop-shadow( 0px 0px 6px rgb(45, 48, 62,0.15));
}


/* .dark .shadow-1 {
    box-shadow: unset;
} */

/* -///////////////////- */
/* -///////////////////- */
/* tincture */

.carbonic-tint {
    fill: #1f1d2b;
}
.dark .carbonic-tint {
    fill: #ffffff;
}
.carbonic-tint-x {
    fill: #ffffff;
}
.dark .carbonic-tint-x {
    fill: #1f1d2b;
}

.tint {
    fill: #c4c4c7;
}

.dark .tint {
    fill: #6c6f78;
}

.tint-stroke {
    stroke: #c4c4c7;
}
.dark .tint-stroke {
    stroke: #6c6f78;
}

.tint-1 {
    fill: #c3c3c6;
}
.dark .tint-1 {
    fill: #72747f;
}

.tint-2 {
    fill: #b8b7bc;
}
.dark .tint-2 {
    fill: #72747f;
}

.tint-item {
    fill: #d5d6d9;
}
.dark .tint-item {
    fill: #6d6f78;
}

.tint-menu {
    fill: #b8b7bc;
}
.dark .tint-menu {
    fill: #96969c;
}

.tint-tab {
    fill: #bebec1;
}
.dark .tint-tab {
    fill: #62606b;
}
.active .tint-tab {
    fill: #dd3643;
}

.tint-main-tab {
    fill: #fff;
}
.dark .tint-main-tab {
    fill: #8b8a91;
}
.active .tint-main-tab {
    fill: #fff;
}

.tint-squer {
    border: solid 2px #d9d9da;
}
.dark .tint-squer {
    border: solid 2px #767982;
} 

.carbonic-tint{
    color: #1f1d2b;
}
.dark .carbonic-tint{
    color: #ffffff;
}

/* Accounts */
.accounts-business-svg {
    fill: #efeff0;
}
.dark .accounts-business-svg {
    fill: #5c5e69;
}

/*  */

/* Accounts */

/* -///////////////////- */
/* -///////////////////- */

.border-left {
    border-left: solid 1px #ebebeb;
}

.dark .border-left {
    border-left: solid 1px #1f1d2b;
}

.border-top {
    border-top: solid 1px #ebebeb;
}

.dark .border-top {
    border-top: solid 1px #1f1d2b;
}

.menu-profile-border {
    border: solid 2px #dcdcde;
}
/* MenuImageBorder */
.dark .menu-profile-border {
    border: solid 2px #3a3a46;
}

/* -///////////////////- */
/* -///////////////////- */

.back-button {
    fill: #c6c6ca;
    top: var(--safe-area-top);
}

.dark .back-button {
    fill: #8f8e95;
}

/* -///////////////////- */
/*  *** *** *** ***  */
/*  *** *** *** ***  */
.home-tab {
    background: linear-gradient(90deg, #bebec1, #bebec1);
    border-radius: 8px;
    height: 48px;
    width: 48px;
}

.home-tab[aria-selected = "true"] {
    background: linear-gradient(90deg, #d0394f, #e83439);
    box-shadow: 0px 0px 25px #e7353c;
    color: #fff;
}

.mode-svg {
    fill: #fff0;
}

.acitve-mode .mode-svg {
    fill: #fff;
}

.item-quantity {
    left: -10px;
    fill: #fff;
    display: flex;
    padding: 0 4px;
    position: absolute;
    border-radius: 25px;
    flex-direction: row;
    align-items: center;
    background-color: #f48114;
    border: solid 2px #d17117;

}
/*  *** *** *** ***  */

/* #3c3f4c40 ; */

/* .text-orange{
    
} */
/* 

export const Orange = "#";
export const Red = "#CB3A53";
export const OrangeBorder = "#d17117";


export const White = darkMode ? "#2D303E" : "#FFF";

export const Bg = darkMode ? "#1F1D2B" : "#F5F5F5";
export const Secondery = darkMode ? "#353848" : "#FBFBFB"; //pink-9

 export const MiddelGreen = "#64B091"; //green-6
export const Border = darkMode ? "#393D4D" : "#E1E1E3"; //gray-
export const Orange = "#F48114";
export const Red = "#CB3A53";
export const OrangeBorder = "#d17117";

export const SearchBg = darkMode ? "#3c3f4c" : "#ffffff";

export const MenuLabel = darkMode ? "#2d2c39" : "#fcfcfc";

export const IconBg = darkMode ? "#5c5e69" : "#efeff0"; //gray-8
export const TextGray = darkMode ? "#767982" : "#C4C4C7"; //gray-8

export const TextGrayTow = darkMode ? "#6C6E78" : "#C3C3C6"; //gray-8

export const Border_2 = darkMode ? "#" : "#";

export const TabTint = darkMode ? "#62606b" : "#bebec1";

export const AccountsName = darkMode ? "#767982" : "#bebec1";

*/

/* Invoices */
.bg-whtie{
    background-color: #fff;
}
.carbonic-card-svg {
    fill: #2d303e;
}
.dark .carbonic-card-svg {
    fill: #ffffff;
}
.invoice-seprater-svg {
    fill: #ffffff;
}
.dark .invoice-seprater-svg {
    fill: #2d303e;
}

.invoice-top {
    background-color: #f5f5f5;
}
.dark .invoice-top {
    background-color: #3d404e;
}

.invoice-shape {
    background-color: #e6e6e6;
}

.dark .invoice-shape {
    background-color: #393c4a;
}
.invoice-shape-svg {
    fill: #e6e6e6;
}

.dark .invoice-shape-svg {
    fill: #393c4a;
}

.invoice-border-svg {
    fill: #ecebe8;
}

.dark .invoice-border-svg {
    fill: #494c5a;
}

.invoice-number-svg {
    fill: #c4c4c7;
}
.dark .invoice-number-svg {
    fill: #4d505c;
}

.invoice-bg-date {
    background-color: #fbfbfb;
    border-top: solid 1px #ebebeb;
}
.dark .invoice-bg-date {
    background-color: #292a39;
    border-top: solid 1px #393d4d;
}

.invoice-bg-date-2 {
    background-color: #fbfbfb;
    border: solid 1px #ededee;
}
.dark .invoice-bg-date-2 {
    background-color: #383b48;
    border: solid 1px #4c4f5b;
}

.invoice-quantity {
    background-color: #c4c4c7;
    border: solid 2px #b9b9bb;
    fill: #fff;
}

.dark .invoice-quantity {
    background-color: #484a57;
    border: solid 2px #3f414e;
    fill: #767982;
}

.invoice-bg-header {
    background-color: #faf9f7;
    border-bottom: solid 1px #e7e6de;
}
.dark .invoice-bg-header {
    background-color: #393c4a;
    border-bottom: solid 1px #4c4f5b;
}

.invoice-bg-items {
    background-color: #ffffff;
}
.dark .invoice-bg-items {
    background-color: #313442;
}

.tint-price-svg {
    fill: #c4c4c7;
}

.dark .tint-price-svg {
    fill: #6c6e78;
}

.barcode-svg {
    fill: #b9b9b9;
}

.dark .barcode-svg {
    fill: #dfe2d3;
}

/* Invoices */

.tab-container-bg {
    background-color: #f5f5f550;
}
.dark .tab-container-bg {
    background-color: #1f1d2b50;
}

.tab-container-bg .border {
    border: solid 1px #f5f5f5;
}
.dark .tab-container-bg .border {
    border: solid 1px #313240;
}



/* Svgs */

.text-input{
    border-radius : 14px;
    padding: 10px;
    fill:#c4c4c7 ;
    background-color: #ffffff;
    border : solid 2px #efeff0;
}
.dark .text-input{
    fill:#767982 ;
    background-color: #3c3f4c;
    border : solid 2px #3c3f4c;
}

.text-input input{
    background-color: #ffffff;
}
.dark .text-input input{
    background-color: #3c3f4c;
}

.dark .icon-card svg{
    fill: #767982;
    stroke: #494c5a;
}
.light .icon-card svg{
    fill: #ecebe8;
    stroke: #bebec1;
}

.select-icon{
    fill: #1f1d2b;
}
.dark .select-icon{
    fill: #9e9fa6;
}
.no-select .select-icon{
    opacity: 0.2;
}

.svg-icon-with-bg{
    padding:10px;
    width: 40px;
    fill: #bebec1;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);
}
.dark .svg-icon-with-bg{
    background-color: #2d303e;
 }

.svg-icon path{
    fill: #c4c4c7;
}
.svg-icon .border{
    fill: #ecebe8;
}

.dark .svg-icon path{
    fill: #767982;
}
.dark .svg-icon .border{
    fill: #494c5a;
}

.label-svg{
    height: 30px;
    width: 30px;
    fill:"#C4C4C7"
}
.dark .label-svg{
    fill:"#767982"
}

.container-checked .svg-icon{
    fill: #f1bac4;
}
.container-checked .svg-icon .border{
    fill: #e87f90;
}
.container-checked .carbonic-3{
    color: #f0b9c5;
}

.opration-svg path{
    fill: #FFFFFF;
} 
.dark .opration-svg path{
    fill: #2d303e;
}

.svg-card{
    fill: #bebec1
}
.dark .svg-card{
    fill: #767982
}


.not-fount .svg-icon{
    fill: #DCDCDD;
    background-color:#f0f1f1 ;
    height: 36px;
    border-radius: 10px;
}
.not-fount .svg-icon .border{
    fill: #f0f1f1;
}

.not-fount p{
    color: #D9DDDD;
}

.active-reps .svg-icon .reps {
    fill: #1bd69a
}

.un-active-reps .svg-icon .reps {
    fill: #dd3643
}

.tint-settings{fill: #1f1d2b; transition:  none;}
.dark .tint-settings{fill: #ffffff;}

.theme .tint-settings{background-color: #1f1d2b;fill: #ffffff;}
.dark .theme .tint-settings{background-color: #ffffff; fill: #1f1d2b;}

.active-package{
    color: #1f1d2b !important; 
    border-inline: solid 2px #d9d9da ;
    flex-grow: 1;
}
.dark .active-package{
    color: #ffffff !important;
    border-inline: solid 2px #767982 ;
}
/* color: #f48114;
}
.orange-text-2 {
    color: #ffa44e;
}
.orange-text-3 {
    color: #f9c089;
}
.dark .orange-text-3 {
    color: #905929; */

/* Svgs */

.read-only-bg{
    border-radius : 14px;
    padding: 10px;
    fill:#c4c4c7 ;
    background-color: #e8e8e9;
    border : solid 2px #efeff0;
}
.read-only-bg input{
    background-color: #e8e8e9;
}

.rounded-1{
    border-radius: 10px;
}
.rounded-2{
    border-radius: 14px;
}
.rounded-3{
    border-radius: 15px;
}
.rounded-4{
    border-radius: 20px;
}
.rounded-5{
    border-radius: 25px;
}


.package-name:hover::after{
    transition: 0.3s;
    color: #f48114;
    content: attr(data-hover);
    padding-left: 2px;
    display: flex;
    /* position: absolute; */
}

.fav-account{
    transition: all 0.1s ease-in-out;
    position: absolute;
    left: 10px;
    top: 10px;
    fill: #c3c3c6;
    height: 25px;
}
.dark .fav-account{
    fill: #6c6e78;
}
.fav-account.active{
    fill: #ffa44e;
}
 
.tooltip:hover:after{
    position: absolute;
    left: 40px;
    top: 10px;
    content: attr(tooltip);
    fill: #ffa44e;
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 12px;
    border-radius: 9px;
    color: #FFFFFF;
    z-index: 99;
    font-family: "'Yekan Bakh'";
    background-color:#dd3643 ;
    opacity: 0.9;
    font-size: 12px;
}

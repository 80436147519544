:root{
    --gap : 4px;
    --box-rounded : 5px;
    --shpa-gape : 2px;
    /* --icon:url("./assets/iron.webp"); */
    --icon:url("./assets/box-1.png");
    /* --icon:url("./assets/svg.png");
    --icon:url("./assets/svg-1.png"); */
    --expold-icon:url("./assets/explod.gif");
    
}
 
@media screen and (max-width: 420px) {
    :root{
        --gap : 2px;
        --box-rounded : 2px;
        --shpa-gape : 1px;
    }
}

#root{
    background-color: #f4c092;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.main-container{
    overflow: hidden;

    background-color: #f4c092;
    /* height: 100vh; */
    /* max-height: 720px; */
    width: calc(100vw - 20px); 
    max-width: 520px; 
    /* aspect-ratio: 1/1; */
    margin: auto;

    border-radius: var(--box-rounded);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#box-container{
    background-color: #cccccc;
    height: 100%;
    aspect-ratio: 1;
    display: grid;
    border-radius: var(--box-rounded);
    padding: var(--gap);
}

.box{
    background-color: #fddfba;
    margin: var(--gap);
    border-radius: var(--box-rounded);
    box-shadow:0px 0px 4px inset #333;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* transition: all 200ms ease-out; */
}

[is="hoverd"]{
    /* background-image: var(--icon); */
    background-color: #fddfba;
    background-color: red;
    box-shadow:0 0 6px inset #333
}

.shape-container{
    display: grid;
    position: relative;
    /* background-color: #ccc; */
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    aspect-ratio: 1;
    /* border-radius: var(--box-rounded); */
    animation: fade-in-up 100ms ease-out;
}
@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.shape-container:hover{
    /* background-image: var(--icon); */
    margin:-5px;
}

.shape-clone{
    position:fixed;
    z-index: 303;
    opacity: 0.5;
    pointer-events: none;
    touch-action: none;
    gap: var(--gap);
    /* transition: all 2000ms ease-out; */
}

.shape-container .box{
    background-image: var(--icon);
    background-color: #7b695a;
    box-shadow:0 0 6px inset #683210;
    border : 1px solid #68321099;
    margin:var(--shpa-gape);
    pointer-events: none;
    touch-action: none;
    -ms-touch-action: none;
}

[is="disabled"]{
    opacity: 0.5;
}


.box-holder{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr ;
    height: 22%;
    gap: 20px;
    aspect-ratio: 3/1;
    background-color: #848480;
    border-radius: var(--box-rounded);
    padding: 10px;
    border: #ccc solid 5px !important;
}

.shape-take-place{
    transition: all 50ms ease-out;
    opacity: 0 !important;
    margin:0 !important;
}

.box-chosen{
    background-image: var(--icon);
}

.box-taken{
    background-image: var(--icon);
}

[is="box-breack"]{
    background-image: var(--icon);
    scale: 1.1;
}

#score{
    position: relative;
    z-index: 303;
    color: #dd3643;
    backdrop-filter: blur(1px);
    transition: all 500ms ease-out;

}
#box-score{
    background-image: var(--icon);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 60px;
    width: 60px;
    position: absolute;
    z-index: 300;
    transition: all 500ms ease-out;
}
.box-explode{
    /* box-shadow:0 0 6px inset #683210 !important;
    border : 1px solid #68321099 !important;
    margin:var(--shpa-gape) !important;
    background-color: #dd3643 !important;
    background-image: var(--expold-icon); */
    background-image:url("./assets/svg.png");
}
@keyframes explode{
    0%{
        border-radius: 10px;
        transform: scale(1);
    }
    50%{
        transform: scale(0);
        border-radius: 50px;
    }
    100%{
        transform: scale(1);
        border-radius: 5px;
    }
}
.sorce-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    font-size: 20px;
    gap: 10px;
    z-index: 303;
    text-align: center;

}

.font-bold {
    font-family: "'Yekan Bakh'";
}
.font-medium {
    font-family: "'Yekan Bakh Medium'";
}

.game-over .box{
    transition: all 500ms ease-out;
    background-color: #7b695a !important;
    box-shadow:0 0 6px inset #683210 !important;
    border : 1px solid #68321099 !important;
    margin:var(--shpa-gape) !important;
    background-color: #dd3643 !important;
    filter: grayscale(1);
}
.game-over #box-score{
    border: none;
    background-color: #dd3643;
    border-radius: 1px;
    box-shadow: unset;
    height: 200px;
    width: unset;
    aspect-ratio: 1;
}
.game-over #score{
    color: #fff !important;
    text-shadow: #000 0 0 5px;
}

.game-over .sorce-container{
    transition: all 500ms ease-out;
    z-index: 10000;
    background-color: #dd3643aa;
    padding-block: 40vh;
    font-size: 40px;
}

.footer{
    position: fixed;
    bottom: 0;
}
.icon {
    height: 30px;
    width: 30px;
    z-index: 400;
}
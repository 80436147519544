
* { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


body {
    scroll-behavior: smooth;
    transition: all 200ms ease-in-out;
    font-size: 15px;
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
}
#root{
    height: 100vh;
    overflow: hidden;
}

.scroller{
    overflow-y: auto;
    max-height: 100%;
}

.scroller::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}


.scroller {
}
.scrller-container {
    overflow-y: auto;
    max-height: 100vh;
} 

